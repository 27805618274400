import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Grid3TopBar,
  GridBox,
  GridContainer,
  Spinner,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  updateProfileActivity,
  setActivity,
} from "../../state/actions/questionnaire"
import { resetProposals } from "../../state/actions/proposals"
import { resetProposalPreviews } from "../../state/actions/proposalPreviews"
import { trackAmplitude } from "../../helpers/amplitude"
import { trackSegmentEvent } from "../../helpers/segment"
import ActivitySelectorSimple from "../ActivitySelector/simple"

const ChangeActivity = ({
  initSelectedId,
  isLoggedIn,
  updateProfileActivity,
  resetProposals,
  resetProposalPreviews,
  setActivity,
}) => {
  let [error, setError] = useState(false)
  let [loading, setLoading] = useState(false)

  const linkBasePath = isLoggedIn
    ? "/myaccount/proposals"
    : "/anonaccount/proposals"

  const successCallback = async (id, title) => {
    setError(false)
    setLoading(true)

    if (isLoggedIn === true) {
      updateProfileActivity(API, graphqlOperation, {
        id,
        details: "",
        stage: 1,
      })
        .then(res => {
          if (res === true) {
            trackSegmentEvent("track", "Change activity", {
              activityId: id,
              activityName: title,
            })

            trackAmplitude("Change activity", {
              activityId: id,
              activityName: title,
            })

            resetProposals()
            resetProposalPreviews()

            navigate(linkBasePath)
          }
          return
        })
        .catch(() => {
          setError(true)
          setLoading(false)
        })
    } else {
      setActivity(id)
      trackSegmentEvent("track", "Change activity", {
        activityId: id,
        activityName: title,
      })

      trackAmplitude("Change activity", {
        activityId: id,
        activityName: title,
      })

      resetProposals()
      resetProposalPreviews()

      navigate(linkBasePath)
    }
  }

  return (
    <>
      <Grid3TopBar>
        <Link to={linkBasePath}>← back to proposals</Link>
      </Grid3TopBar>

      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4}>
          <Title level={2} standalone>
            Change my activity
          </Title>
        </GridBox>
      </GridContainer>

      <GridContainer type={4}>
        <GridBox colSpans={[[2, 5], [3, 4], [3, 6]]} type={4}>
          <Standalone>
            <p>Pick a different activity to view other logo proposals.</p>
            {error ? (
              <p>
                <b>
                  There was an error updating your activity. Please try again.
                </b>
              </p>
            ) : null}
            {loading ? (
              <Spinner />
            ) : (
              <ActivitySelectorSimple
                buttonLabel="confirm →"
                initSelectedId={initSelectedId}
                successCallback={successCallback}
              />
            )}
          </Standalone>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ questionnaire, user }) => ({
    initSelectedId: `_${questionnaire.activityId}`,
    initDetails: questionnaire.activityName,
    isLoggedIn: user.isLoggedIn,
  }),
  { updateProfileActivity, resetProposals, resetProposalPreviews, setActivity }
)(ChangeActivity)
