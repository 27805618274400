import React from "react"
import AnswerButtons from "../QuestionnaireApp/AnswerButtons"
import { activities } from "./defines"

const ActivitySelectorSimple = ({ initSelectedId, successCallback }) => {
  const handleSelect = id => {
    successCallback(id, activities[id].title)
  }

  return (
    <>
      <AnswerButtons
        answers={Object.keys(activities)
          .map(k => activities[k])
          .sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort))}
        handleSelect={handleSelect}
        selectedId={initSelectedId}
      />
    </>
  )
}

export default ActivitySelectorSimple
