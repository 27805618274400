import React from "react"
import ProfileRouteAnonymous from "../../../../components/auth/ProfileRoute/Anonymous"
import ChangeActivity from "../../../../components/MyAccount/ChangeActivity"

const ChangeActivityAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ChangeActivity />
  </ProfileRouteAnonymous>
)

export default ChangeActivityAnonymousPage
